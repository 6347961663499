import { updateAppState } from '../appState';
import Token from '../config/token';

type LoginParam = {
  accessToken: string;
  refreshToken: string;
};

export const loginAction = ({ accessToken, refreshToken }: LoginParam) => {
  localStorage.setItem('accessToken', accessToken);
  localStorage.setItem('refreshToken', refreshToken);

  const parsedAccessToken = new Token(accessToken);
  const parsedRefreshToken = new Token(refreshToken);

  updateAppState((state) => {
    state.authentication.accessToken = parsedAccessToken;
    state.authentication.refreshToken = parsedRefreshToken;
    state.authentication.loading = false;
    state.isAuthenticated = true;
  });
};
