import JWT from 'jsonwebtoken';

export interface DecodedToken {
  tt: string;
  id: string;
  iat: number;
  exp: number;
  role?: string;
  roomId?: string;
  sessionId?: string;
  participantId?: string;
}

export enum TokenStatus {
  CouldNotBeDecoded = 'CouldNotBeDecoded',
  Expired = 'Expired',
  ShouldBeRefreshed = 'ShouldBeRefreshed',
  Valid = 'Valid'
}

export enum TokenUserRole {
  Proctor = 'Proctor',
  Admin = 'Admin',
  Student = 'Student'
}

export default class Token {
  get status(): TokenStatus {
    if (this.raw === 'none') {
      return TokenStatus.CouldNotBeDecoded;
    }
    if (
      this.canExpire &&
      this.expiresAt &&
      this.expiresAt <= new Date().getTime()
    ) {
      return TokenStatus.Expired;
    }
    if (
      this.canExpire &&
      this.refreshAt &&
      this.refreshAt <= new Date().getTime()
    ) {
      return TokenStatus.ShouldBeRefreshed;
    }
    return TokenStatus.Valid;
  }

  public readonly raw: string;

  public readonly id: string = '';

  public readonly tt: string = '';

  public readonly issuedAt: number = -1;

  public readonly role: string = '';

  public readonly roomId?: string = '';

  public readonly sessionId?: string = '';

  public readonly participantId?: string = '';

  public readonly expiresAt: number | null = null;

  public readonly refreshAt: number | null = null;

  private canExpire = true;

  private clockSkew = 0;

  constructor(raw: string) {
    let decodedToken: DecodedToken | null = null;
    try {
      const decode = JWT.decode(raw);
      if (typeof decode === 'object' && decode !== null && decode.iat) {
        decodedToken = decode as DecodedToken;
      }
    } catch (e) {
      console.error(e);
    }

    if (decodedToken === null) {
      this.raw = 'none';
      return;
    }

    this.raw = raw;
    this.id = decodedToken.id;
    this.tt = decodedToken.tt;
    this.issuedAt = decodedToken.iat;
    this.role = decodedToken.role as keyof typeof TokenUserRole;
    this.roomId = decodedToken.roomId;
    this.sessionId = decodedToken.sessionId;
    this.participantId = decodedToken.participantId;

    if (decodedToken.iat != null && decodedToken.exp != null) {
      // Got iat and exp body fields
      const currentTimeStamp = new Date().getTime() / 1000;
      this.clockSkew = decodedToken.iat - currentTimeStamp;

      const serverTimeAsLocalTimeStamp = currentTimeStamp + this.clockSkew;
      const tokenValidityInSeconds = decodedToken.exp - decodedToken.iat;

      this.expiresAt = new Date(
        (serverTimeAsLocalTimeStamp + tokenValidityInSeconds) * 1000
      ).getTime();
      this.refreshAt = new Date(
        (serverTimeAsLocalTimeStamp + tokenValidityInSeconds * 0.5) * 1000
      ).getTime();
    } else {
      this.canExpire = false;
    }
  }
}
