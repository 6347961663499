import { updateAppState } from '../appState';
import Token, { TokenStatus } from '../config/token';

export const setAuthenticationTokensAction = ({
  accessToken,
  refreshToken
}: {
  accessToken: string;
  refreshToken: string;
}) => {
  localStorage.setItem('accessToken', accessToken);
  localStorage.setItem('refreshToken', refreshToken);

  const parsedAccessToken = new Token(accessToken);
  const parsedRefreshToken = new Token(refreshToken);

  updateAppState((state) => {
    state.authentication.refreshToken = parsedRefreshToken;
    state.authentication.accessToken = parsedAccessToken;
    state.authentication.loading = false;
    state.isAuthenticated = parsedAccessToken.status === TokenStatus.Valid;
  });
};
