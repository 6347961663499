import { updateAppState } from '../appState';

export const logoutAction = () => {
  localStorage.removeItem('accessToken');
  localStorage.removeItem('refreshToken');
  updateAppState((state) => {
    state.authentication = {
      accessToken: null,
      refreshToken: null,
      sessionId: '',
      id: '',
      role: '',
      loading: false
    };
    state.isAuthenticated = false;
  });
};
